<template>
  <v-row class="ma-0">
    <v-col cols="12" class="d-flex ma-0 pa-0 pb-6">
      <v-spacer></v-spacer>
      <v-btn @click="createNew" tile min-width="5%">
        <span class="mr-1">{{ $t('buttons.add') }}</span>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-col>
    <statistic-component/>
  </v-row>
</template>

<script>
export default {
  name: 'Recipes',
  methods: {
    createNew () {
      this.$router.push({ name: 'admin.create.recipe' }).then()
    }
  }
}
</script>

<style scoped>

</style>
